// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil, isEmpty, map, get, findIndex } from "@mefisto/utils";
// Framework
import { Autocomplete, ColorChip, TextField } from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  form: { touched, errors, setFieldValue },
  field: { name, value },
  label,
  placeholder,
  helperText,
  multiple,
  options,
  renderOption,
  autocompleteProps,
  textFieldProps,
  getChipProps,
  onChange,
}) => {
  // Props
  const fieldError = get(errors, name);
  const showError = !isNil(fieldError) && get(touched, name);
  const defaultValue = multiple ? [] : null;
  // Handlers
  const handleChange = useCallback(
    (event, value) => {
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );
  const handleDelete = useCallback(() => {
    setFieldValue(name, "");
  }, [setFieldValue, name]);
  // Render
  return (
    <Autocomplete
      autoHighlight
      value={isEmpty(value) ? defaultValue : value}
      multiple={multiple}
      options={options ?? []}
      renderTags={(value, getTagProps) =>
        map(value, (option, index) => {
          // console.log("🟢VALUE", { value, props: getTagProps({ index }) });
          return (
            <ColorChip
              label={option}
              {...getTagProps({ index })}
              {...getChipProps?.(option, index)}
            />
          );
        })
      }
      renderInput={(params) => {
        // Render
        return (
          <TextField
            {...params}
            {...textFieldProps}
            label={label}
            placeholder={placeholder}
            helperText={showError ? fieldError : helperText}
            error={showError}
            inputProps={{
              ...params.inputProps,
              // Value is rendered with color chips
              value: "",
              // value,
            }}
            InputLabelProps={{
              shrink: true,
              ...textFieldProps.InputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              ...(!multiple &&
                !isEmpty(value) && {
                  startAdornment: (
                    <ColorChip
                      label={value}
                      onDelete={handleDelete}
                      {...getChipProps?.(value, findIndex(options, value))}
                    />
                  ),
                }),
            }}
          />
        );
      }}
      renderOption={renderOption}
      onChange={onChange ?? handleChange}
      {...autocompleteProps}
    />
  );
};

const FormAutocompleteField = ({
  autocompleteProps = {},
  textFieldProps = { variant: "outlined" },
  ...props
}) => (
  <FormField
    {...props}
    component={Component}
    autocompleteProps={autocompleteProps}
    textFieldProps={textFieldProps}
  />
);

FormAutocompleteField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  getChipProps: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormAutocompleteField;
