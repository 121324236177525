// React
import React from "react";
// Framework
import { forwardType, Themes, LightTheme } from "@mefisto/web";

export default forwardType(Themes, () => (
  <Themes>
    <LightTheme
      theme={{
        palette: {
          primary: {
            dark: "#c07215",
            main: "#ed982a",
            light: "#e79f50",
          },
          secondary: {
            dark: "#a5454c",
            main: "#ce666d",
            light: "#d0777d",
          },
          dividerMale: "#dbe2e6",
          dividerFemale: "#e6dbdb",
        },
      }}
    />
  </Themes>
));
